import { SearchListStructure } from '@/utils';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import type GroupMemberModal from './model';

const GroupMember = ({ store }: { store: GroupMemberModal }) => {
  const { mainStore, visible, onClose } = store;
  return (
    <Modal
      title="群成员列表"
      centered={true}
      open={visible}
      onCancel={onClose}
      width="40%"
      footer={null}
    >
      <div>
        <SearchListStructure
          className="!h-[500px]"
          store={mainStore}
        />
      </div>
    </Modal>
  );
};

export default observer(GroupMember);
