import {
  type BaseData,
  type IMainSubStructureModel,
  type NormalProgrammeParams,
  SearchListModal,
  request,
} from '@/utils';
import { action, observable } from 'mobx';

export default class GroupMemberModal {
  @observable public visible = false;
  @observable public chatId: string;
  @action public onShow = (id: string) => {
    this.visible = true;
    this.chatId = id;
    this.mainStore.grid.onQuery();
  };

  @action public onClose = () => {
    this.visible = false;
    this.mainStore.programme.reset();
  };

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        span: 8,
        type: 'input',
        field: 'name',
        label: '成员群昵称：',
        placeholder: '请输入成员群昵称',
      },
    ],
  };

  @observable public grid: IMainSubStructureModel = {
    grid: {
      columns: [
        {
          key: 'name',
          name: '成员群昵称',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: false,
      pageSize: 20,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        return request<BaseData<any>>({
          url: '/pbb/platform/sys/corpWechat/getGroupMembers',
          method: 'POST',
          data: { ...postParams, chatId: this.chatId },
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });
}
