import { SearchListStructure } from '@/utils';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import GroupMember from './modal/index';
import Model from './model';

const WeChatGroupList = () => {
  const store = useMemo(() => new Model(), []);
  const { mainStore, groupMemberStore } = store;
  return (
    <div>
      <SearchListStructure store={mainStore} />
      <GroupMember store={groupMemberStore} />
    </div>
  );
};

export default observer(WeChatGroupList);
