import {
  type BaseData,
  type IMainSubStructureModel,
  type NormalProgrammeParams,
  RenderByPermission,
  SearchListModal,
  request,
} from '@/utils';
import { Button, message } from 'antd';
import { action, observable } from 'mobx';
import GroupMemberModal from './modal/model';

export default class Modal {
  constructor() {
    this.mainStore.grid.onQuery();
  }

  @observable public groupMemberStore: GroupMemberModal = new GroupMemberModal();

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'groupName',
        label: '群名称：',
      },
      {
        type: 'input',
        field: 'ownerName',
        label: '群主昵称：',
      },
    ],
  };

  @action public upData = (ids: any[]) => {
    const chatIds = ids.map((id) => ({ chat_id: id }));
    request({
      url: '/pbb/platform/sys/corpWechat/updateGroupMembers',
      method: 'POST',
      data: chatIds,
    }).then((res) => {
      message.success('更新成功');
      this.mainStore.grid.onQuery();
    });
  };

  @observable public grid: IMainSubStructureModel = {
    buttons: [
      {
        text: '批量更新',
        permissionId: '3',
        handleClick: () => {
          const selectedIds = this.mainStore.grid.gridModel.selectedIds;

          if (Array.from(selectedIds).length === 0) {
            message.warning('请选择需要更新的群');
            return;
          }
          this.upData(Array.from(selectedIds));
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'operation',
          name: '操作',
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');

            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_147`}>
                  <Button
                    type="link"
                    onClick={() => {
                      this.groupMemberStore.onShow(row.chatId);
                    }}
                  >
                    群成员
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    type="link"
                    onClick={() => {
                      this.upData([row.chatId]);
                    }}
                  >
                    更新
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '群名称',
        },
        {
          key: 'owner',
          name: '群主昵称',
        },
        {
          key: 'memberCount',
          name: '群成员数',
        },
        {
          key: 'updateTime',
          name: '更新时间',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'chatId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: false,
      pageSize: 20,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        return request<BaseData<any>>({
          url: '/pbb/platform/sys/corpWechat/getGroupList',
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };
  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });
}
